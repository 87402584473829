<template>
  <div>
    <vue-data-table
      :tableColumns="columns"
      :tableRows="rows"
      :isLoading="isLoading"
      :showRowTableAction="false"
      :empty-rows-message="$t('components.table.no_data')"
      @[needDataUpdateEvent]="init"
    >
      <template #table-row="props">
        <a href="#" @click.prevent="showDetail(props.row.getRaw)">{{ props.cell.getValue }}</a>
      </template>
    </vue-data-table>
  </div>
</template>

<script>
import VueDataTable, { Column, VUE_DATA_TABLE_NEED_DATA_UPDATE } from "@/components/table";
import { SHOW_WORK_SHIFT_DETAIL_EVENT } from "@/pages/responsible/planing/workshift/data/events";

import { WorkShift } from "@/pages/responsible/planing/workshift/data";
import api from "@/api/workshift";

export default {
  components: {
    VueDataTable,
  },
  data() {
    return {
      columns: [
        new Column({
          id: "code",
          name: "pages.planing-team.workshift.code",
        }),
        new Column({
          id: "description",
          name: "pages.planing-team.workshift.description",
        }),
      ],
      rows: [],
      isLoading: false,
    };
  },
  computed: {
    needDataUpdateEvent() {
      return VUE_DATA_TABLE_NEED_DATA_UPDATE;
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.isLoading = true;
      const { data } = await api.getWorkShifts();
      this.rows = data.map((x) => new WorkShift().parse(x));
      this.isLoading = false;
    },
    showDetail(workshift) {
      EventBus.fire(SHOW_WORK_SHIFT_DETAIL_EVENT, {
        code: workshift.code,
        description: workshift.description,
        date: new Date(),
      });
    },
  },
};
</script>

<style></style>
