export default class WorkShift {
  parse(data) {
    this.key = data.key;
    this.code = data.code;
    this.description = data.description;
    this.planStatusOpen = data.planStatusOpen;

    return this;
  }
}
