import { parseTimeElseNull } from "@/utils/functions";

export default class WorkShiftHour {
  parse(data) {
    this.codePlan = data.codePlan;
    this.dailyHours = data.dailyHours;
    this.key = data.key;
    this.nightHours = data.nightHours;
    this.planDescription = data.planDescription;
    this.planStatus = data.planStatus;
    this.totalHours = data.totalHours;
    this.weekDay = data.weekDay;

    if (data.firstPartStartingTime == "00:00:00" && data.firstPartEndingTime == "00:00:00") {
      this.firstPartStartingTime = null;
      this.firstPartEndingTime = null;
    } else {
      this.firstPartStartingTime = parseTimeElseNull(data.firstPartStartingTime);
      this.firstPartEndingTime = parseTimeElseNull(data.firstPartEndingTime);
    }

    if (data.secondPartStartingTime == "00:00:00" && data.secondPartEndingTime == "00:00:00") {
      this.secondPartStartingTime = null;
      this.secondPartEndingTime = null;
    } else {
      this.secondPartStartingTime = parseTimeElseNull(data.secondPartStartingTime);
      this.secondPartEndingTime = parseTimeElseNull(data.secondPartEndingTime);
    }

    return this;
  }
}
