<template>
  <vue-modal
    :modalTitle="title"
    :showSpinner="isLoading"
    :showModal="showModal"
    :loading-primary-footer-button="isLoading"
    :modalLarge="true"
    :showSecondaryFooterButton="false"
    :showPrimaryFooterButton="false"
    @[closeEvent]="onCloseButtonClicked"
  >
    <template #body>
      <div class="row mb-2">
        <div class="col text-right font-weight-bold">
          {{ dayName }}, <formatted-date-component :val="date" />
        </div>
      </div>
      <vue-data-table
        :tableColumns="columns"
        :tableRows="rows"
        :showRowTableAction="false"
        :empty-rows-message="$t('components.table.no_data')"
      >
        <template #table-row="props">
          <template v-if="props.cell.getColumn.id == 'firstPartStartingTime'">
            <div :class="{ 'font-weight-bold': isCurrentWeekDay(props.cell.getRaw.weekDay) }">
              <formatted-date-component
                :val="props.cell.getRaw.firstPartStartingTime"
                :withoutDate="true"
                :withTime="true"
              />
              <span> - </span>
              <formatted-date-component
                :val="props.cell.getRaw.firstPartEndingTime"
                :withoutDate="true"
                :withTime="true"
              />
            </div>
          </template>
          <template v-else-if="props.cell.getColumn.id == 'secondPartStartingTime'">
            <div :class="{ 'font-weight-bold': isCurrentWeekDay(props.cell.getRaw.weekDay) }">
              <formatted-date-component
                :val="props.cell.getRaw.secondPartStartingTime"
                :withoutDate="true"
                :withTime="true"
              />
              <span> - </span>
              <formatted-date-component
                :val="props.cell.getRaw.secondPartEndingTime"
                :withoutDate="true"
                :withTime="true"
              />
            </div>
          </template>
          <template v-else-if="props.cell.getColumn.id == 'weekDay'">
            <div :class="{ 'font-weight-bold': isCurrentWeekDay(props.cell.getRaw.weekDay) }">
              {{ dayTranslation(props.cell.getValue) }}
            </div>
          </template>
          <template v-else>
            <div :class="{ 'font-weight-bold': isCurrentWeekDay(props.cell.getRaw.weekDay) }">
              {{ props.cell.getValue }}
            </div>
          </template>
        </template>
      </vue-data-table>
    </template>
  </vue-modal>
</template>

<script>
import VueModal, { CLOSE_BUTTON_CLICKED_MODAL_EVENT } from "@/components/modal";
import { SHOW_WORK_SHIFT_DETAIL_EVENT } from "@/pages/responsible/planing/workshift/data/events";
import VueDataTable, { Column } from "@/components/table";
import { WorkShiftHour } from "@/pages/responsible/planing/workshift/data";
import { FormattedDateComponent } from "@/components/formatted";
import { getDay } from "@/utils/functions";

import api from "@/api/workshift";

export default {
  components: {
    VueModal,
    VueDataTable,
    FormattedDateComponent,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      description: null,
      date: new Date(),
      raw: [],
      rows: [],
      columns: [
        new Column({
          id: "weekDay",
          name: "pages.planing-team.workshift.day",
        }),
        new Column({
          id: "firstPartStartingTime",
          name: "pages.planing-team.workshift.first_part_time",
        }),
        new Column({
          id: "secondPartStartingTime",
          name: "pages.planing-team.workshift.second_part_time",
        }),
        new Column({
          id: "totalHours",
          name: "pages.planing-team.workshift.total_hours",
        }),
      ],
    };
  },
  computed: {
    title() {
      return this.$isset(this.description) ? this.description : "";
    },
    closeEvent() {
      return CLOSE_BUTTON_CLICKED_MODAL_EVENT;
    },
    dayName() {
      return this.$t(`components.date.day_long[${getDay(this.date)}]`);
    },
  },
  created() {
    EventBus.listen(SHOW_WORK_SHIFT_DETAIL_EVENT, ({ code, description, date }) => {
      this.code = code;
      this.description = description;
      this.date = date;
      this.rows = [];
      this.showModal = true;
      this.init();
    });
  },
  beforeDestroy() {
    EventBus.off(SHOW_WORK_SHIFT_DETAIL_EVENT);
  },
  methods: {
    async init() {
      this.isLoading = true;
      const { data } = await api.getWorkShiftHours(this.code);
      this.rows = data.map((x) => new WorkShiftHour().parse(x));
      this.raw = data;
      this.isLoading = false;
    },
    onCloseButtonClicked() {
      this.showModal = false;
    },
    /**
     * Day index starting from 1, 1 - monday
     */
    dayTranslation(index) {
      return this.$t(`components.date.day_long[${index}]`);
    },
    isCurrentWeekDay(index) {
      return getDay(this.date) == index;
    },
  },
};
</script>
