import axios from "axios";
import { buildUrl } from "@/config";

export default {
  getWorkShifts() {
    return axios.get(buildUrl(`api/v1/workshift/work-shift-items`));
  },

  getWorkShiftHours(code) {
    return axios.get(buildUrl(`api/v1/workshift/work-shift-hours/${code}`));
  },

  createWorkShift(data) {
    return axios.post(buildUrl(`api/v1/workshift/add`), data);
  },
};
